import React, { useState } from 'react';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import ColorPickerDialogForMui from './ColorPickerDialogForMui';
import Box from '@mui/material/Box';

const ColorPickerButton = ({ btnPrefix, color, handleColorChangeViaMenu, handleColorChangeViaPicker }) => {
    const [ isColorPickerVisible, setIsColorPickerVisible ] = useState(false);

    const handleColorPickerToggle = () => {
        setIsColorPickerVisible(!isColorPickerVisible);
    };

    return (<>
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                // p: 0.5,
                boxShadow: isColorPickerVisible ? 1 : 2, // "unset",
                // color: "primary.contrastText",
                // bgcolor: "primary.main",
                borderRadius: '20px',
            }}
        >
            <Button
                // color="secondary"
                variant="outlined"
                onClick={handleColorPickerToggle}
                sx={{
                    fontSize: 'small',
                    textTransform: 'capitalize',
                    // margin: 1,
                    width: "100%",
                    // borderColor: "black",
                    justifyContent: 'space-evenly',
                    color: 'inherit',
                    borderColor: 'inherit',
                }}
                endIcon={isColorPickerVisible ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            ><Box
                sx={{
                    display: "flex",
                }}
            >
                    <Typography
                        sx={{
                            fontSize: { xs: 'small', sm: 'medium' },
                        }}
                    >
                        {btnPrefix}
                    </Typography>
                    <Box
                        sx={{
                            width: 24,
                            height: 24,
                            marginRight: 1,
                            backgroundColor: color,
                            borderRadius: "50%",
                            boxShadow: 15,
                            borderWidth: 'thin',
                            borderColor: 'cyan',
                            borderStyle: 'solid',

                        }}
                    />
                </Box>
            </Button>

            <Collapse in={isColorPickerVisible}>
                <ColorPickerDialogForMui
                    title="Select a color:"
                    color={color}
                    handleColorChangeViaMenu={handleColorChangeViaMenu}
                    handleColorChangeViaPicker={handleColorChangeViaPicker}
                />
            </Collapse>
        </Box>
    </>
    );
};
export default ColorPickerButton;