import React from 'react';
import { Stack, Box, Typography, Select, MenuItem } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import useTheme from '@mui/material/styles/useTheme';
import { SketchPicker } from 'react-color';
import { LIGHT_MODE_COLORS, DARK_MODE_COLORS } from '../../theme/constants';

export default function ColorPickerDialogForMui({ title, color, handleColorChangeViaMenu, handleColorChangeViaPicker }) {
    // console.log("ColorPickerDialogForMui passed color", color);
    const theme = useTheme();
    const muiThemeColors = theme.mode === "dark" ? Object.keys(DARK_MODE_COLORS) : Object.keys(LIGHT_MODE_COLORS);
    const hexMuiColors = muiThemeColors.map(key => theme.palette[ key ].main);
    // Convert color to HEX if it's an object
    // const selectedColor = typeof color === 'object' && color.hex ? color.hex : color;
    const [ selectedColor, setSelectedColor ] = React.useState(color);
    const handleColorChange = (event) => {
        const newColor = event.target.value;
        // console.log("newColor", newColor);
        setSelectedColor(newColor);
        handleColorChangeViaMenu(event);
    };
    return (
        <Stack spacing={2} sx={{ width: "inherit", padding: [ 0.25, 0.5, 2 ] }}>

            <FormControl fullWidth>
                <InputLabel id="theme-colors-label">Theme Colors</InputLabel>

                <Select
                    value={color}
                    onChange={handleColorChange}
                    label="theme colors"
                    sx={{
                        color: 'inherit',
                        bgcolor: 'inherit',
                        boxShadow: 1,
                        borderWidth: 'thin',
                        borderColor: 'cyan',
                        borderStyle: 'solid',
                    }}
                >
                    <MenuItem value="non-theme" key="non-theme">
                        <Stack direction="row" spacing={2}>

                            <Box
                                style={{
                                    width: 24,
                                    height: 24,
                                    // backgroundColor: color,
                                    marginRight: 1,
                                    display: "inline-block",
                                    boxShadow: 1,
                                    // borderWidth: 'thin',
                                    // borderColor: 'cyan',
                                    // borderStyle: 'solid',
                                }}
                            />
                            <Typography className="selectedColor"
                                sx={{ fontSize: { xs: 'small', sm: 'medium' } }}
                            >
                                Not theme color
                            </Typography>
                        </Stack>
                    </MenuItem>
                    {/* <MenuItem value={color} key={muiThemeColors[ i ]}>{muiThemeColors[ i ]}</MenuItem> */}
                    {hexMuiColors.map((color, i) => {
                        return (
                            <MenuItem value={color} key={muiThemeColors[ i ]}
                            >
                                <Stack direction="row" spacing={2}>
                                    <Box
                                        style={{
                                            width: 24,
                                            height: 24,
                                            backgroundColor: color,
                                            marginRight: 1,
                                            display: "inline-block",
                                            boxShadow: 1,
                                            borderWidth: 'thin',
                                            borderColor: 'cyan',
                                            borderStyle: 'solid',
                                        }}
                                    />
                                    <Typography className="selectedColor"
                                        sx={{ fontSize: { xs: 'small', sm: 'medium' } }}
                                    >
                                        {muiThemeColors[ i ]}
                                    </Typography>
                                </Stack>
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
            {selectedColor === "non-theme" && (
                <SketchPicker color={color} onChangeComplete={handleColorChangeViaPicker} />
            )}
        </Stack>
    );
}