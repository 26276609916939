import React, { useRef, useState, useCallback, useMemo } from 'react'
import { color } from 'framer-motion';
import { Box, Slider, Stack, Typography, Dialog, DialogTitle, Backdrop, Button } from '@mui/material'
import { FormControl, InputLabel, Select, MenuItem, Tab, Tabs, } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import useTheme from '@mui/material/styles/useTheme'
import Divider from '@mui/material/Divider';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import styled from '@mui/material/styles/styled';
import { debounce } from '@mui/material/utils';
import SwipeableViews from 'react-swipeable-views';
import DraggablePaper from "../DraggablePaper";
import SvgInParentPaddings from './SvgInParentPaddings';
import ColorPickerDialogForMui from "../ColorPickerDialogForMui"
import { horizontalWavesPaths, verticalWavesPaths } from "../animatedSvgImages/animateSvgInParentPaddingsConfig"
import ColorPickerButton from "../ColorPickerButton"
import SettingsForOnePadding from "./SettingsForOnePadding"
import TabPanel from '../TabPanel';
import PaddingSettingsTabs from './PaddingSettingsTabs';

const maxHeight = 450;

const Slider1 = styled(Slider)({
    marginTop: "1rem",
    color: 'inherit',
    '& .MuiSlider-markLabel': {
        top: '20px',
        fontSize: "small",
        // backgroundColor: 'yellow',
        color: 'inherit',

    },
    '& .MuiSlider-valueLabel': {
        fontSize: "x-small",
        top: "2px",
        color: 'inherit',
        // backgroundColor: 'cyan',
    },
});

const dialogWidth = 350;
const Typography1 = styled(Typography)({
    marginBottom: "0",
    fontSize: "small",
    fontWeight: 'bold',
});



const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
    // zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
}));




const colorOptions = [ 'primary.main', 'secondary.main', 'warning.main', /* ... */ ];

const SvgInParentPaddingsWithSettings = () => {
    const refParent = useRef(null);
    const theme = useTheme();
    const [ padding, setPadding ] = useState({
        top: 100,
        right: 100,
        bottom: 100,
        left: 100
    });


    const [ topPathPars, setTopPathPars ] = useState({
        wavesNumber: 13,
        svgPathName: "increasingAmplitude",
        widthCoeff: 1,
        heightCoeff: 1,
    });
    const [ rightPathPars, setRightPathPars ] = useState({
        wavesNumber: 13,
        svgPathName: "increasingAmplitude",
        widthCoeff: 1,
        heightCoeff: 1,
    });
    const [ bottomPathPars, setBottomPathPars ] = useState({
        wavesNumber: 13,
        svgPathName: "decreasingAmplitude",
        widthCoeff: 1,
        heightCoeff: 1,
    });
    const [ leftPathPars, setLeftPathPars ] = useState({
        wavesNumber: 13,
        svgPathName: "decreasingAmplitude",
        widthCoeff: 1,
        heightCoeff: 1,
    });

    const [ fillColor, setFillColor ] = useState(theme.palette.primary.main);
    const [ strokeColor, setStrokeColor ] = useState(theme.palette.secondary.main);

    const [ boxHeight, setBoxHeight ] = useState(500);
    const [ boxWidth, setBoxWidth ] = useState(350);

    const [ open, setOpen ] = useState(false);
    const [ isColorPickerVisible, setIsColorPickerVisible ] = useState(false);


    const debouncedSetPadding = useRef({});

    // const handleSliderChange = useCallback((direction) => {
    //     if (!debouncedSetPadding.current[ direction ]) {
    //         // Adjust the debounced function to accept two arguments: event and newValue
    //         debouncedSetPadding.current[ direction ] = debounce((event, newValue) => {
    //             // Use the newValue directly if it's provided, otherwise fall back to extracting it from the event
    //             const value = typeof newValue === 'number' ? newValue : event.target.value;
    //             setPadding((prev) => {
    //                 const newPadding = { ...prev, [ direction ]: value };
    //                 const horizontalPadding = newPadding.left + newPadding.right;
    //                 const verticalPadding = newPadding.top + newPadding.bottom;
    //                 if (horizontalPadding > boxWidth || verticalPadding > boxHeight) {
    //                     return prev;
    //                 }
    //                 return newPadding;
    //             });
    //         }, 10);
    //     }

    //     // Return a wrapper function that correctly handles the arguments from the Slider1 onChange event
    //     return (event, newValue) => debouncedSetPadding.current[ direction ](event, newValue);
    // }, [ boxWidth, boxHeight ]); // Dependencies


    const handleSliderChange = useCallback((direction) => {
        return (event, newValue) => {
            const value = typeof newValue === 'number' ? newValue : event.target.value;
            setPadding((prev) => {
                const newPadding = { ...prev, [ direction ]: value };
                const horizontalPadding = newPadding.left + newPadding.right;
                const verticalPadding = newPadding.top + newPadding.bottom;
                if (horizontalPadding > boxWidth || verticalPadding > boxHeight) {
                    return prev;
                }
                return newPadding;
            });
        };
    }, [ boxWidth, boxHeight ]); // Dependencies

    // const handleSliderChange = (direction) => debounce((event, newValue) => {
    //     setPadding(prev => {
    //         const newPadding = { ...prev, [ direction ]: newValue };
    //         const horizontalPadding = newPadding.left + newPadding.right;
    //         const verticalPadding = newPadding.top + newPadding.bottom;
    //         if (horizontalPadding > boxWidth || verticalPadding > boxHeight) {
    //             return prev; // If the new padding would exceed the box dimensions, ignore the update
    //         }
    //         return newPadding;
    //     });
    // }, 100); // Delay of 100ms





    const handleTopPathParsChange = (property) => (event, newValue) => {
        setTopPathPars(prev => ({ ...prev, [ property ]: newValue }));
    }
    const handleRightPathParsChange = (property) => (event, newValue) => {
        setRightPathPars(prev => ({ ...prev, [ property ]: newValue }));
    }
    const handleBottomPathParsChange = (property) => (event, newValue) => {
        setBottomPathPars(prev => ({ ...prev, [ property ]: newValue }));
    }
    const handleLeftPathParsChange = (property) => (event, newValue) => {
        setLeftPathPars(prev => ({ ...prev, [ property ]: newValue }));
    };

    const handleTopSvgPathNameChange = (event) => {
        setTopPathPars(prev => ({ ...prev, svgPathName: event.target.value }));
    };
    const handleRightSvgPathNameChange = (event) => {
        setRightPathPars(prev => ({ ...prev, svgPathName: event.target.value }));
    };
    const handleBottomSvgPathNameChange = (event) => {
        setBottomPathPars(prev => ({ ...prev, svgPathName: event.target.value }));
    };
    const handleLeftSvgPathNameChange = (event) => {
        setLeftPathPars(prev => ({ ...prev, svgPathName: event.target.value }));
    };

    const handleFillColorChangeViaMenu = (event) => {
        setFillColor(event.target.value);
    };
    const handleFillColorChangeViaPicker = (color) => {
        setFillColor(color.hex);
    };

    const handleStrokeColorChangeViaMenu = (event) => {
        setStrokeColor(event.target.value);
    };
    const handleStrokeColorChangeViaPicker = (color) => {
        setStrokeColor(color.hex);
    };

    const handleHeightChange = useCallback((event, newValue) => {
        const verticalPadding = padding.top + padding.bottom;
        if (newValue < verticalPadding) setPadding(prev => {
            const halfHeight = Math.floor(newValue / 2);
            const newPadding = { ...prev, top: halfHeight, bottom: halfHeight };
            return newPadding;
        });
        setBoxHeight(newValue);
    }, [ padding.top, padding.bottom ]);

    const handleWidthChange = useCallback((event, newValue) => {
        const horizontalPadding = padding.left + padding.right;
        if (horizontalPadding > newValue) setPadding(prev => {
            const halfWidth = Math.floor(newValue / 2);
            const newPadding = { ...prev, left: halfWidth, right: halfWidth };
            return newPadding;
        });
        setBoxWidth(newValue);
    }, [ padding.left, padding.right ]);

    const handleOpen = useCallback(() => setOpen(true), []);
    const handleClose = useCallback(() => setOpen(false), []);

    const handleColorPickerToggle = () => {
        setIsColorPickerVisible(!isColorPickerVisible);
    };


    const boxParentStyles = useMemo(() => ({
        mt: '20px',
        position: 'relative',
        height: `${boxHeight}px`,
        width: `${boxWidth}px`,
        borderStyle: 'groove',
        paddingTop: `${padding.top}px`,
        paddingRight: `${padding.right}px`,
        paddingBottom: `${padding.bottom}px`,
        paddingLeft: `${padding.left}px`,
        overflowX: 'auto',
    }), [ boxHeight, boxWidth, padding ]);

    return (
        <Box
            sx={{

                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                justifyContent: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
                height: `${maxHeight + 200}px`,
            }}
        >
            <Typography variant="h5" gutterBottom sx={{ fontSize: 'medium' }}>
                Waves in Parent Paddings
            </Typography>
            <Box
                ref={refParent}
                sx={boxParentStyles}
            >
                <SvgInParentPaddings refParent={refParent}
                    key={`padding-${padding.top}-${padding.right}-${padding.bottom}-${padding.left}-${boxHeight}-${boxWidth}`}
                    options={{

                        topPathPars: topPathPars,
                        rightPathPars: rightPathPars,
                        bottomPathPars: bottomPathPars,
                        leftPathPars: leftPathPars,

                        fillColor: fillColor,
                        strokeColor: strokeColor
                    }}
                />
                <Box 
                sx={{ width: "100%", height: "100%", bgcolor: "warning.main" }} 
                className="inner-box" 
                    
                />
            </Box>
            <Divider />
            <Button className="clay"
                variant="contained"
                color="warning"
                onClick={handleOpen}
                sx={{
                    maxWidth: "100px",
                    fontSize: 'small',
                    fontWeight: '400',
                    textTransform: 'capitalize',
                    margin: '1rem',
                }}            >
                Adjust Waves
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                components={{
                    Backdrop: CustomBackdrop
                }}
                PaperComponent={DraggablePaper}
                PaperProps={{
                    sx: {
                        // backgroundColor: 'rgba(255, 255, 255, 0.8)', // Make the dialog semi-transparent
                        bgcolor: 'inherit',
                        color: 'inherit',
                        width: 'inherit',
                        padding: '1.25rem', // [ "1rem", "0.25rem" ],
                        margin: 0,
                    }
                }}
                scroll={'paper'}
                sx={{
                    borderLeftStyle: 'inset',
                    borderRightStyle: 'inset',
                    bgcolor: "primary2.main",
                    // bgcolor: 'maroon',
                    color: "primary2.contrastText",
                    // color: 'yellow',
                    margin: 1,
                    position: 'absolute',
                    left: [ 0, `calc(100% - ${dialogWidth}px)` ], // Position the dialog to the right of the box
                    // right: 0,
                    top: 0, // Align the top of the dialog with the top of the box
                    width: `${dialogWidth}px`,
                    maxWidth: '100%',
                    // padding: 2, //[ 0.25, 0.5, 2 ],
                    // overflowX: 'auto', // Add horizontal scrollbar when necessary
                }}
            >
                <DialogTitle style={{ cursor: 'move', fontSize: 'medium' }} id="draggable-dialog-title" component="h4">
                    Svg In Parent Paddings
                </DialogTitle>
                <Stack spacing={2}
                    sx={{
                        width: "inherit",
                        padding: 0.25, // [ 0.25, 0.5, 2 ],
                        mb: 1,
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            // p: 0.5,
                            boxShadow: 1,

                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                pl: 1,
                                pr: 1,
                            }} >
                            <Typography1 id="inner-height-slider" gutterBottom
                            >
                                Height
                            </Typography1>
                            <Slider1
                                value={boxHeight}
                                onChange={handleHeightChange}
                                aria-labelledby="inner-height-slider"
                                valueLabelDisplay="on"
                                step={10}
                                marks={[
                                    { value: 100, label: '100px' },
                                    { value: maxHeight, label: `${maxHeight}px` },
                                ]} min={100}
                                max={maxHeight}
                            />
                        </Box>
                        <Box
                            sx={{
                                width: "100%",
                                pl: 1,
                                pr: 1,
                            }}
                        >
                            <Typography1 id="inner-width-slider" gutterBottom>
                                Width
                            </Typography1>
                            <Slider1
                                value={boxWidth}
                                onChange={handleWidthChange}
                                aria-labelledby="inner-width-slider"
                                valueLabelDisplay="on"
                                step={10}
                                marks={[
                                    { value: 100, label: '100px' },
                                    { value: 600, label: `600px` },
                                ]}
                                min={100}
                                max={600}
                            />
                        </Box>
                    </Box>
                    <Divider />

                    <Box>
                        <Typography1 id="padding-top-slider" gutterBottom>
                            Padding Top
                        </Typography1>
                        <Slider1
                            value={padding.top}
                            onChange={handleSliderChange('top')}
                            aria-labelledby="padding-top-slider"
                            valueLabelDisplay="on"
                            step={10}
                            marks={[
                                { value: 0, label: '0px' },
                                { value: boxHeight, label: `${boxHeight}px` },
                            ]}
                            min={0}
                            max={boxHeight}
                        />
                    </Box>
                    <Box>
                        <Typography1 id="padding-right-slider" gutterBottom>
                            Padding Right
                        </Typography1>
                        <Slider1
                            value={padding.right}
                            onChange={handleSliderChange('right')}
                            aria-labelledby="padding-right-slider"
                            valueLabelDisplay="on"
                            step={10}
                            marks={[
                                { value: 0, label: '0px' },
                                { value: boxWidth, label: `${boxWidth}px` },
                            ]}
                            min={0}
                            max={boxWidth}
                        />
                    </Box>
                    <Box>
                        <Typography1 id="padding-bottom-slider" gutterBottom>
                            Padding Bottom
                        </Typography1>
                        <Slider1
                            value={padding.bottom}
                            onChange={handleSliderChange('bottom')}
                            aria-labelledby="padding-bottom-slider"
                            valueLabelDisplay="on"
                            step={10}
                            marks={[
                                { value: 0, label: '0px' },
                                { value: boxHeight, label: `${boxHeight}px` },
                            ]}
                            min={0}
                            max={boxHeight}
                        />
                    </Box>
                    <Box>
                        <Typography1 id="padding-left-slider" gutterBottom>
                            Padding Left
                        </Typography1>
                        <Slider1
                            value={padding.left}
                            onChange={handleSliderChange('left')}
                            aria-labelledby="padding-left-slider"
                            valueLabelDisplay="on"
                            step={10}
                            marks={[
                                { value: 0, label: '0px' },
                                { value: boxWidth, label: `${boxWidth}px` },
                            ]}
                            min={0}
                            max={boxWidth}
                        />
                    </Box>
                    <Divider />
                    <ColorPickerButton
                        btnPrefix="Fill: "
                        color={fillColor}
                        handleColorChangeViaMenu={handleFillColorChangeViaMenu}
                        handleColorChangeViaPicker={handleFillColorChangeViaPicker}

                    />
                    <ColorPickerButton
                        btnPrefix="Stroke: "
                        color={strokeColor}
                        handleColorChangeViaMenu={handleStrokeColorChangeViaMenu}
                        handleColorChangeViaPicker={handleStrokeColorChangeViaPicker}

                    />
                </Stack>

                <Divider sx={{
                    mt: 1,
                    mb: 1,
                }} />


                <PaddingSettingsTabs topPathPars={topPathPars} handleTopSvgPathNameChange={handleTopSvgPathNameChange} handleTopPathParsChange={handleTopPathParsChange} rightPathPars={rightPathPars} handleRightSvgPathNameChange={handleRightSvgPathNameChange} handleRightPathParsChange={handleRightPathParsChange} bottomPathPars={bottomPathPars} handleBottomSvgPathNameChange={handleBottomSvgPathNameChange} handleBottomPathParsChange={handleBottomPathParsChange} leftPathPars={leftPathPars} handleLeftSvgPathNameChange={handleLeftSvgPathNameChange} handleLeftPathParsChange={handleLeftPathParsChange} />

            </Dialog>
            {/* </Draggable> */}
        </Box>
    )
}

export default SvgInParentPaddingsWithSettings
