import React from 'react'
import Box from '@mui/material/Box';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
            style={{ width: "92%" }}
        >
            {value === index && (
                <Box sx={{
                    // padding: '10px',
                    // paddingTop: '10px'
                    fontSize: "small",
                }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

export default TabPanel