import React, { memo, useCallback } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SwipeableViews from 'react-swipeable-views';
import TabPanel from '../TabPanel'; // Assuming TabPanel is a separate component
import SettingsForOnePadding from './SettingsForOnePadding'; // Assuming SettingsForOnePadding is a separate component

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

const sxFontSmall = {
    fontSize: "small",
}
// function a11yProps(index) {
//     return {
//         id: `full-width-tab-${index}`,
//         'aria-controls': `full-width-tabpanel-${index}`,
//     };
// }

const PaddingSettingsTabs = memo(({ topPathPars, handleTopSvgPathNameChange, handleTopPathParsChange, rightPathPars, handleRightSvgPathNameChange, handleRightPathParsChange, bottomPathPars, handleBottomSvgPathNameChange, handleBottomPathParsChange, leftPathPars, handleLeftSvgPathNameChange, handleLeftPathParsChange }) => {

    const [ tabValue, setTabValue ] = React.useState(0);



    const handleTabChange = useCallback((event, newValue) => {
        setTabValue(newValue);
    }, []);

    const handleChangeTabIndex = useCallback((index) => {
        setTabValue(index);
    }, []);


    return (
        <>
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="padding settings"
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
            >
                <Tab label="Top" sx={sxFontSmall} {...a11yProps(0)} />
                <Tab label="Right" sx={sxFontSmall} {...a11yProps(1)} />
                <Tab label="Bottom" sx={sxFontSmall} {...a11yProps(2)} />
                <Tab label="Left" sx={sxFontSmall} {...a11yProps(3)} />
            </Tabs>
            <SwipeableViews
                axis='x'
                index={tabValue}
                onChangeIndex={handleChangeTabIndex}
                style={{ minHeight: "300px" }}
            >
                <TabPanel value={tabValue} index={0}>
                    <SettingsForOnePadding paddingDirection="Top" paddDirectionPathPars={topPathPars} handlePaddDirectionSvgPathNameChange={handleTopSvgPathNameChange} handlePaddDirectionPathParsChange={handleTopPathParsChange} />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <SettingsForOnePadding paddingDirection="Right" paddDirectionPathPars={rightPathPars} handlePaddDirectionSvgPathNameChange={handleRightSvgPathNameChange} handlePaddDirectionPathParsChange={handleRightPathParsChange} />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <SettingsForOnePadding paddingDirection="Bottom" paddDirectionPathPars={bottomPathPars} handlePaddDirectionSvgPathNameChange={handleBottomSvgPathNameChange} handlePaddDirectionPathParsChange={handleBottomPathParsChange} />
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                    <SettingsForOnePadding paddingDirection="Left" paddDirectionPathPars={leftPathPars} handlePaddDirectionSvgPathNameChange={handleLeftSvgPathNameChange} handlePaddDirectionPathParsChange={handleLeftPathParsChange} />
                </TabPanel>
            </SwipeableViews>
        </>
    )
});

export default PaddingSettingsTabs;