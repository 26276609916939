import React from 'react'
import { Paper, PaperProps, Box } from '@mui/material';

import Draggable from 'react-draggable';

function DraggablePaper(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Box {...props} />
    </Draggable>
  );
}


export default DraggablePaper