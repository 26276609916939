import React, { memo } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, Slider, Typography as Typography1 } from '@mui/material';
import styled from '@mui/material/styles/styled';

import { horizontalWavesPaths, verticalWavesPaths } from "../animatedSvgImages/animateSvgInParentPaddingsConfig";

const sxFontSmall = {
    fontSize: "small",
};

const Slider1 = styled(Slider)({
    marginTop: "1rem",
    color: 'inherit',
    '& .MuiSlider-markLabel': {
        top: '20px',
        fontSize: "small",
        // backgroundColor: 'yellow',
        color: 'inherit',

    },
    '& .MuiSlider-valueLabel': {
        fontSize: "x-small",
        top: "2px",
        color: 'inherit',
        // backgroundColor: 'cyan',
    },
});

const SettingsForOnePadding = memo(({ paddingDirection, paddDirectionPathPars, handlePaddDirectionSvgPathNameChange, handlePaddDirectionPathParsChange }) => {
    return (
        <>
            <FormControl fullWidth sx={{ marginTop: '1rem', }}>
                <InputLabel id={`${paddingDirection}PathPars-svgPathName-label`}
                    sx={{
                        ...sxFontSmall,
                        color: 'inherit',
                        bgcolor: 'inherit',
                    }}                >{paddingDirection} waves shape</InputLabel>
                <Select
                    labelId={`${paddingDirection}PathPars-svgPathName-label`}
                    id={`${paddingDirection}PathPars-svgPathName`}
                    value={paddDirectionPathPars.svgPathName}
                    label={`${paddingDirection} Path Pars svgPathName`}
                    onChange={handlePaddDirectionSvgPathNameChange}
                    sx={{
                        ...sxFontSmall,
                        color: 'inherit',
                        bgcolor: 'inherit',
                    }}
                >
                    {[ "Right", "Left" ].includes(paddingDirection) ? Object.keys(verticalWavesPaths(1, 1, 1)).map((key) => (
                        <MenuItem key={key} value={key} sx={sxFontSmall}>{key}</MenuItem>
                    ))
                        : Object.keys(horizontalWavesPaths(1, 1, 1)).map((key) => (
                            <MenuItem key={key} value={key} sx={sxFontSmall}>{key}</MenuItem>
                        ))}
                </Select>
            </FormControl >
            <Box key={`${paddingDirection}PathPars-wavesNumber`}
                sx={{
                    paddingLeft: '1rem',
                }}
            >
                <Typography1 id={`${paddingDirection}PathPars-slider-wavesNumber`} gutterBottom>
                    {paddingDirection} wavesNumber
                </Typography1>
                <Slider1
                    value={paddDirectionPathPars.wavesNumber}
                    onChange={handlePaddDirectionPathParsChange("wavesNumber")}
                    aria-labelledby={`${paddingDirection}PathPars-slider-wavesNumber`}
                    valueLabelDisplay="on"
                    step={1}
                    marks={[
                        { value: 1, label: '1' },
                        { value: 20, label: '20' },
                    ]} min={1}
                    max={20} />
            </Box>

            <Box
                sx={{
                    paddingLeft: '1rem',
                }}
                key={`${paddingDirection}PathPars-heightCoeff`}>
                <Typography1 id={`${paddingDirection}PathPars-slider-heightCoeff`} gutterBottom>
                    {paddingDirection} heightCoeff
                </Typography1>
                <Slider1
                    value={paddDirectionPathPars.heightCoeff}
                    onChange={handlePaddDirectionPathParsChange("heightCoeff")}
                    aria-labelledby={`${paddingDirection}PathPars-slider-heightCoeff`}
                    valueLabelDisplay="on"
                    step={0.1}
                    marks={[
                        { value: 0.2, label: '0.2' },
                        { value: 0.5, label: '0.5' },
                        { value: 1.0, label: '1.0' },
                    ]}
                    min={0.2}
                    max={1} />
            </Box>
            <Box
                sx={{
                    paddingLeft: '1rem',
                }}
                key={`${paddingDirection}PathPars-widthCoeff`}>
                <Typography1 id={`${paddingDirection}PathPars-slider-widthCoeff`} gutterBottom>
                    {paddingDirection} widthCoeff
                </Typography1>
                <Slider1
                    value={paddDirectionPathPars.widthCoeff}
                    onChange={handlePaddDirectionPathParsChange("widthCoeff")}
                    aria-labelledby={`${paddingDirection}PathPars-slider-widthCoeff`}
                    valueLabelDisplay="auto"
                    step={0.1}
                    marks={[
                        { value: 0.2, label: '0.2' },
                        { value: 0.5, label: '0.5' },
                        { value: 1.0, label: '1.0' },
                    ]}
                    min={0.2}
                    max={1}
                    aria-label="width coeff"
                    defaultValue={1} />
            </Box>
        </>
    );
})

export default SettingsForOnePadding;